define("ember-mu-transform-helpers/transforms/string-set", ["exports", "@ember/utils", "@ember/debug", "@ember-data/serializer/transform"], function (_exports, _utils, _debug, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StringSetTransform extends _transform.default {
    deserialize(serialized) {
      (true && !(!serialized || (0, _utils.typeOf)(serialized) === 'array') && (0, _debug.assert)(`Expected array but got ${(0, _utils.typeOf)(serialized)}`, !serialized || (0, _utils.typeOf)(serialized) === 'array'));
      return serialized || [];
    }
    serialize(deserialized) {
      (true && !(!deserialized || (0, _utils.typeOf)(deserialized) === 'array') && (0, _debug.assert)(`Expected array but got ${(0, _utils.typeOf)(deserialized)}`, !deserialized || (0, _utils.typeOf)(deserialized) === 'array'));
      return deserialized || [];
    }
  }
  _exports.default = StringSetTransform;
});