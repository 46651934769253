define("ember-data-table/components/data-table/text-search", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/runloop", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _runloop, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      submitForm=this.submitForm
      placeholder=this.placeholder
      handleAutoInput=this.handleAutoInput
      handleDirectInput=this.handleDirectInput
      filter=@filter
      auto=@auto)}}
  
  */
  {
    "id": "6Fif3k3Q",
    "block": "[[[18,3,[[28,[37,1],null,[[\"submitForm\",\"placeholder\",\"handleAutoInput\",\"handleDirectInput\",\"filter\",\"auto\"],[[30,0,[\"submitForm\"]],[30,0,[\"placeholder\"]],[30,0,[\"handleAutoInput\"]],[30,0,[\"handleDirectInput\"]],[30,1],[30,2]]]]]],[1,\"\\n\"]],[\"@filter\",\"@auto\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-data-table/components/data-table/text-search.hbs",
    "isStrictMode": false
  });
  let TextSearchComponent = (_class = class TextSearchComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "enteredValue", undefined);
      _defineProperty(this, "autoDebouncePid", undefined);
    }
    handleAutoInput(event) {
      this.enteredValue = event.target.value;
      this.autoDebouncePid = (0, _runloop.debounce)(this, this.submitCurrent, this.args.wait);
    }
    submitCurrent() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.args.updateFilter(this.enteredValue);
        this.autoDebouncePid = undefined;
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this.autoDebouncePid);
    }
    handleDirectInput(event) {
      this.enteredValue = event.target.value;
    }
    submitForm(event) {
      event.preventDefault();
      this.submitCurrent();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleAutoInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleAutoInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDirectInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDirectInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype)), _class);
  _exports.default = TextSearchComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextSearchComponent);
});