define("ember-data-table/components/data-table/data-table-menu-general", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @data-table.selectionIsEmpty}}
    {{yield}}
  {{/if}}
  
  */
  {
    "id": "PisSIfSd",
    "block": "[[[41,[30,1,[\"selectionIsEmpty\"]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],null]],[\"@data-table\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-menu-general.hbs",
    "isStrictMode": false
  });
  class DataTableMenuGeneralComponent extends _component2.default {}
  _exports.default = DataTableMenuGeneralComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableMenuGeneralComponent);
});