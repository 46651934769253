define("ember-data-table/components/data-table/data-table-menu-selected", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      selectionIsEmpty=@data-table.selectionIsEmpty
      selectionCount=this.selectionCount
      clearSelection=this.clearSelection
      selection=@data-table.selection
      data-table=@data-table)}}
  
  {{!-- TODO: must we pass the data table itself?  It is shared with the consumers. --}}
  */
  {
    "id": "9OeGnPs1",
    "block": "[[[18,2,[[28,[37,1],null,[[\"selectionIsEmpty\",\"selectionCount\",\"clearSelection\",\"selection\",\"data-table\"],[[30,1,[\"selectionIsEmpty\"]],[30,0,[\"selectionCount\"]],[30,0,[\"clearSelection\"]],[30,1,[\"selection\"]],[30,1]]]]]],[1,\"\\n\\n\"]],[\"@data-table\",\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-menu-selected.hbs",
    "isStrictMode": false
  });
  let DataTableMenuSelectedComponent = (_class = class DataTableMenuSelectedComponent extends _component2.default {
    constructor() {
      super(...arguments);
      this.args['data-table'].enableSelection = true; // TODO: is this the best way to handle such case?
    }

    get selectionCount() {
      return this.args['data-table'].selection.length;
    }
    clearSelection() {
      this.args['data-table'].clearSelection();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clearSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelection"), _class.prototype)), _class);
  _exports.default = DataTableMenuSelectedComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableMenuSelectedComponent);
});