define("ember-mu-transform-helpers/transforms/language-string", ["exports", "@ember/debug", "@ember/utils", "@ember-data/serializer/transform"], function (_exports, _debug, _utils, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LangString = void 0;
  const LangString = function (content, lang) {
    this.content = content;
    this.language = lang;
    this.toString = function () {
      return `${this['content']} (${this['language']})`;
    };
  };
  _exports.LangString = LangString;
  class LangStringTransform extends _transform.default {
    deserialize(serialized) {
      (true && !(!serialized || (0, _utils.typeOf)(serialized) === 'object') && (0, _debug.assert)(`Expected object but got ${(0, _utils.typeOf)(serialized)}`, !serialized || (0, _utils.typeOf)(serialized) === 'object'));
      if (serialized != null) return new LangString(serialized['content'], serialized['language']);else return null;
    }
    serialize(deserialized) {
      (true && !(!deserialized || (0, _utils.typeOf)(deserialized) === 'object') && (0, _debug.assert)(`Expected object but got ${(0, _utils.typeOf)(deserialized)}`, !deserialized || (0, _utils.typeOf)(deserialized) === 'object'));
      return deserialized;
    }
  }
  _exports.default = LangStringTransform;
});