define("ember-data-table/mixins/default-query-params", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _mixin.default.create({
    page: 0,
    size: 10,
    filter: ''
  });
  _exports.default = _default;
});