define("ember-data-table/components/data-table/data-table-content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertDefinition = convertDefinition;
  _exports.deUnderscoreString = deUnderscoreString;
  _exports.default = void 0;
  _exports.splitDefinitions = splitDefinitions;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
       (hash
         Header=(component "data-table/data-table-content-header"
           enableSelection=@enableSelection
           enableLineNumbers=@enableLineNumbers
           updateSort=@updateSort
           hasLinks=this.hasLinks
           customHeaders=@customHeaders
           data-table=@data-table)
         Body=(component "data-table/data-table-content-body"
           content=@content
           enableSelection=@enableSelection
           enableLineNumbers=@enableLineNumbers
           noDataMessage=@noDataMessage
           onClickRow=@onClickRow
           linkedRoutes=this.linkedRoutes
           rowLink=@rowLink
           customFields=@customFields
           data-table=@data-table)
         dataTable=@data-table)}}
  
  */
  {
    "id": "KVIz0JAz",
    "block": "[[[18,11,[[28,[37,1],null,[[\"Header\",\"Body\",\"dataTable\"],[[50,\"data-table/data-table-content-header\",0,null,[[\"enableSelection\",\"enableLineNumbers\",\"updateSort\",\"hasLinks\",\"customHeaders\",\"data-table\"],[[30,1],[30,2],[30,3],[30,0,[\"hasLinks\"]],[30,4],[30,5]]]],[50,\"data-table/data-table-content-body\",0,null,[[\"content\",\"enableSelection\",\"enableLineNumbers\",\"noDataMessage\",\"onClickRow\",\"linkedRoutes\",\"rowLink\",\"customFields\",\"data-table\"],[[30,6],[30,1],[30,2],[30,7],[30,8],[30,0,[\"linkedRoutes\"]],[30,9],[30,10],[30,5]]]],[30,5]]]]]],[1,\"\\n\"]],[\"@enableSelection\",\"@enableLineNumbers\",\"@updateSort\",\"@customHeaders\",\"@data-table\",\"@content\",\"@noDataMessage\",\"@onClickRow\",\"@rowLink\",\"@customFields\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-content.hbs",
    "isStrictMode": false
  });
  function splitDefinitions(string) {
    return (string || "").split(" ").filter(x => x !== "");
  }

  /**
   * Transforms __ to _ and _ to space.
   */
  function deUnderscoreString(string) {
    const arrString = [];

    // executing this with a regex turned out to be less clear
    let idx = 0;
    while (idx < string.length) {
      let current = string[idx];
      let next = string[idx + 1];
      if (current === "_" && next === "_") {
        arrString.push("_");
        idx = idx + 2;
      } else if (current === "_") {
        arrString.push(" ");
        idx = idx + 1;
      } else {
        arrString.push(current);
        idx = idx + 1;
      }
    }
    return arrString.join("");
  }
  function convertDefinition(string) {
    const parts = string.split(":");
    return {
      route: parts[0],
      label: (parts[1] || null) && deUnderscoreString(parts[1]),
      icon: (parts[2] || null) && deUnderscoreString(parts[2]),
      rawLabel: parts[1] || null,
      rawIcon: parts[2] || null
    };
  }
  class DataTableContentComponent extends _component2.default {
    get hasLinks() {
      return this.linkedRoutes.length > 0;
    }

    /**
     * Accepts and transforms definitions for linked routes.
     *
     * Implementations may transform this at will.  The default
     * transformation splits on `:` assuming the first part is the route
     * and the second part is the label.  If no label is given, it is
     * passed as null.  If a label is given, all underscores are
     * transformed to spaces and double underscores are left as a single
     * _.  We split again on a third `:`, transforming in the same way for
     * the suggested icon.
     *
     * Behaviour for `___` is undefined.
     *
     * Yields an array of objects to represent the linked routes.
     * [ { route: "products.show", label: "Show product", icon: "show-icon" } ]
     */
    get linkedRoutes() {
      return splitDefinitions(this.args.links || "").map(convertDefinition);
    }
  }

  // exposed for testing
  _exports.default = DataTableContentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableContentComponent);
});