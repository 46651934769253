define("ember-mu-transform-helpers/transforms/language-string-set", ["exports", "@ember/utils", "@ember/debug", "ember-mu-transform-helpers/transforms/language-string", "@ember-data/serializer/transform"], function (_exports, _utils, _debug, _languageString, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LanguageStringSetTransform extends _transform.default {
    deserialize(serialized) {
      (true && !(!serialized || (0, _utils.typeOf)(serialized) === 'array') && (0, _debug.assert)(`Expected array but got ${(0, _utils.typeOf)(serialized)}`, !serialized || (0, _utils.typeOf)(serialized) === 'array'));
      serialized = serialized.map(function (item) {
        return new _languageString.LangString(item['content'], item['language']);
      });
      return serialized;
    }
    serialize(deserialized) {
      (true && !(!deserialized || (0, _utils.typeOf)(deserialized) === 'array') && (0, _debug.assert)(`Expected array but got ${(0, _utils.typeOf)(deserialized)}`, !deserialized || (0, _utils.typeOf)(deserialized) === 'array'));
      return deserialized;
    }
  }
  _exports.default = LanguageStringSetTransform;
});