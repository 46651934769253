define("ember-mu-transform-helpers/transforms/phone", ["exports", "@ember/debug", "@ember-data/serializer/transform"], function (_exports, _debug, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PhoneTransform extends _transform.default {
    deserialize(serialized) {
      if (serialized) {
        if (serialized.match(/^tel:/)) {
          return serialized.substring('tel:'.length);
        } else {
          (true && (0, _debug.warn)(`Expected telephone URI but got ${JSON.stringify(serialized)} as value`));
        }
      }
      return serialized;
    }
    serialize(deserialized) {
      if (deserialized) {
        return 'tel:' + deserialized;
      } else {
        return null;
      }
    }
  }
  _exports.default = PhoneTransform;
});