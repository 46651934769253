define("ember-data-table/components/data-table/row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      wrapper=@wrapper
      item=@wrapper.item
      enableLineNumbers=@enableLineNumbers
      lineNumber=(add @index @offset)
      enableSelection=@enableSelection
      selected=(includes @wrapper.item @selection)
      hasClickRowAction=@hasClickRowAction
      onClickRow=(fn @onClickRow @wrapper.item)
      toggleSelected=(fn @toggleSelected @wrapper)
      isSelected=(includes @wrapper.item @selection)
      linkedRoutes=@linkedRoutes
      customFields=@customFields
      DataCells=(component
        "data-table/default-data-table-content-body"
        linkedRoute=@linkedRoute
        customFields=@customFields
        data-table=@data-table))}}
  */
  {
    "id": "kvIl6Xwk",
    "block": "[[[18,14,[[28,[37,1],null,[[\"wrapper\",\"item\",\"enableLineNumbers\",\"lineNumber\",\"enableSelection\",\"selected\",\"hasClickRowAction\",\"onClickRow\",\"toggleSelected\",\"isSelected\",\"linkedRoutes\",\"customFields\",\"DataCells\"],[[30,1],[30,1,[\"item\"]],[30,2],[28,[37,2],[[30,3],[30,4]],null],[30,5],[28,[37,3],[[30,1,[\"item\"]],[30,6]],null],[30,7],[28,[37,4],[[30,8],[30,1,[\"item\"]]],null],[28,[37,4],[[30,9],[30,1]],null],[28,[37,3],[[30,1,[\"item\"]],[30,6]],null],[30,10],[30,11],[50,\"data-table/default-data-table-content-body\",0,null,[[\"linkedRoute\",\"customFields\",\"data-table\"],[[30,12],[30,11],[30,13]]]]]]]]]],[\"@wrapper\",\"@enableLineNumbers\",\"@index\",\"@offset\",\"@enableSelection\",\"@selection\",\"@hasClickRowAction\",\"@onClickRow\",\"@toggleSelected\",\"@linkedRoutes\",\"@customFields\",\"@linkedRoute\",\"@data-table\",\"&default\"],false,[\"yield\",\"hash\",\"add\",\"includes\",\"fn\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table/row.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});