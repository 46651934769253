define("ember-data-table/mixins/route", ["exports", "@ember/object/mixin", "lodash/merge"], function (_exports, _mixin, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint unused:false */
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    queryParams: {
      filter: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      size: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    mergeQueryOptions() {
      return {};
    },
    model(params) {
      const options = {
        sort: params.sort,
        page: {
          number: params.page,
          size: params.size
        }
      };
      // TODO: sending an empty filter param to backend returns []
      if (params.filter) {
        options['filter'] = params.filter;
      }
      (0, _merge.default)(options, this.mergeQueryOptions(params));
      return this.store.query(this.modelName, options);
    },
    actions: {
      loading(transition) {
        let controller = this.controllerFor(this.routeName);
        controller.set('isLoadingModel', true);
        transition.promise.finally(function () {
          controller.set('isLoadingModel', false);
        });
        return true; // bubble the loading event
      }
    }
  });
  _exports.default = _default;
});