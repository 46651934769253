define("ember-data-table/components/data-table", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/tracking", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _object, _tracking, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      Search=(component "data-table/text-search"
         filter=@filter
         auto=this.autoSearch
         updateFilter=this.updateFilter
         wait=this.searchDebounceTime
         placeholder=this.searchPlaceholder)
      Content=(component "data-table/data-table-content"
         content=@content
         noDataMessage=this.noDataMessage
         enableSelection=this.enableSelection
         enableLineNumbers=@lineNumbers
         onClickRow=@onClickRow
         updateSort=this.updateSort
         customHeaders=@customHeaders
         customFields=@customFields
         links=@links
         rowLink=@rowLink
         data-table=this)
      Pagination=(component "data-table/number-pagination"
         page=@page
         size=@size
         itemsOnCurrentPage=@content.length
         sizeOptions=this.sizeOptions
         total=@content.meta.count
         links=@content.meta.pagination
         updatePage=@updatePage
         updateSize=this.updatePageSize)
      Menu=(component "data-table/data-table-menu"
         data-table=this)
      content=@content
      enableSearch=this.enableSearch
      dataTable=this)}}
  
  */
  {
    "id": "UCZObbwf",
    "block": "[[[18,12,[[28,[37,1],null,[[\"Search\",\"Content\",\"Pagination\",\"Menu\",\"content\",\"enableSearch\",\"dataTable\"],[[50,\"data-table/text-search\",0,null,[[\"filter\",\"auto\",\"updateFilter\",\"wait\",\"placeholder\"],[[30,1],[30,0,[\"autoSearch\"]],[30,0,[\"updateFilter\"]],[30,0,[\"searchDebounceTime\"]],[30,0,[\"searchPlaceholder\"]]]]],[50,\"data-table/data-table-content\",0,null,[[\"content\",\"noDataMessage\",\"enableSelection\",\"enableLineNumbers\",\"onClickRow\",\"updateSort\",\"customHeaders\",\"customFields\",\"links\",\"rowLink\",\"data-table\"],[[30,2],[30,0,[\"noDataMessage\"]],[30,0,[\"enableSelection\"]],[30,3],[30,4],[30,0,[\"updateSort\"]],[30,5],[30,6],[30,7],[30,8],[30,0]]]],[50,\"data-table/number-pagination\",0,null,[[\"page\",\"size\",\"itemsOnCurrentPage\",\"sizeOptions\",\"total\",\"links\",\"updatePage\",\"updateSize\"],[[30,9],[30,10],[30,2,[\"length\"]],[30,0,[\"sizeOptions\"]],[30,2,[\"meta\",\"count\"]],[30,2,[\"meta\",\"pagination\"]],[30,11],[30,0,[\"updatePageSize\"]]]]],[50,\"data-table/data-table-menu\",0,null,[[\"data-table\"],[[30,0]]]],[30,2],[30,0,[\"enableSearch\"]],[30,0]]]]]],[1,\"\\n\"]],[\"@filter\",\"@content\",\"@lineNumbers\",\"@onClickRow\",\"@customHeaders\",\"@customFields\",\"@links\",\"@rowLink\",\"@page\",\"@size\",\"@updatePage\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table.hbs",
    "isStrictMode": false
  });
  let DataTable = (_class = class DataTable extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_selection", _descriptor, this);
      _initializerDefineProperty(this, "_enableSelection", _descriptor2, this);
      _defineProperty(this, "_size", undefined);
      _initializerDefineProperty(this, "hasMenu", _descriptor3, this);
    }
    get selection() {
      if (this._selection === undefined && this.args.selection === undefined) return [];else if (this._selection !== undefined) return this._selection;else return this.args.selection;
    }
    set selection(newSelection) {
      this._selection = newSelection; // also trigers dependent properties
    }

    get noDataMessage() {
      return this.args.noDataMessage === undefined ? 'No data' : this.args.noDataMessage;
    }
    get isLoading() {
      return this.args.isLoading;
    }
    get searchDebounceTime() {
      return this.args.searchDebounceTime === undefined ? 2000 : this.args.searchDebounceTime;
    }
    get enableSelection() {
      return this._enableSelection === undefined ? this.args.hasMenu : this._enableSelection;
    }
    set enableSelection(value) {
      this._enableSelection = value;
    }
    get selectionIsEmpty() {
      return this.selection.length === 0;
    }
    get enableSizes() {
      return this.args.enableSizes === undefined ? true : this.args.enableSizes;
    }
    get sort() {
      return this.args.sort;
    }
    get page() {
      return this.args.page;
    }
    get size() {
      if (this._size === undefined && this.args.size) return this.args.size;else if (this._size) return this._size;else return 5;
    }
    set size(newSize) {
      this._size = newSize;
    }
    get sizeOptions() {
      if (!this.enableSizes) {
        return null;
      } else {
        const sizeOptions = this.args.sizes || [5, 10, 25, 50, 100];
        if (!sizeOptions.includes(this.size)) {
          sizeOptions.push(this.size);
        }
        sizeOptions.sort((a, b) => a - b);
        return sizeOptions;
      }
    }
    // old comment: set from inner component, migth fail with nested if

    get enableSearch() {
      return this.args.filter !== undefined;
    }
    get autoSearch() {
      return this.args.autoSearch === undefined ? true : this.args.autoSearch;
    }
    get parsedFields() {
      const fields = this.args.fields;
      if ((0, _utils.typeOf)(fields) === 'string') {
        return fields.split(' ');
      } else {
        return fields || [];
      }
    }
    get searchPlaceholder() {
      return this.args.searchPlaceholder === undefined ? 'Search input' : this.args.searchPlaceholder;
    }
    updatePageSize(size) {
      this.args.updatePage(0);
      this.args.updatePageSize(size);
    }
    updateFilter(filter) {
      this.args.updatePage(0);
      this.args.updateFilter(filter);
    }
    updateSort(sort) {
      this.args.updatePage(0);
      this.args.updateSort(sort);
    }
    addItemToSelection(item) {
      this.selection = [item, ...this.selection];
    }
    removeItemFromSelection(item) {
      this.selection = this.selection.filter(x => x !== item);
    }
    clearSelection() {
      this.selection = [];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_selection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_enableSelection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasMenu", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updatePageSize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePageSize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSort"), _class.prototype)), _class);
  _exports.default = DataTable;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTable);
});