define("ember-data-table/components/data-table/data-table-menu", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
       (component "data-table/data-table-menu-general" data-table=@data-table)
       (component "data-table/data-table-menu-selected" data-table=@data-table)
       as |general selected|}}
    {{yield general selected}}
  {{/let}}
  */
  {
    "id": "rxheP6WP",
    "block": "[[[44,[[50,\"data-table/data-table-menu-general\",0,null,[[\"data-table\"],[[30,1]]]],[50,\"data-table/data-table-menu-selected\",0,null,[[\"data-table\"],[[30,1]]]]],[[[1,\"  \"],[18,4,[[30,2],[30,3]]],[1,\"\\n\"]],[2,3]]]],[\"@data-table\",\"general\",\"selected\",\"&default\"],false,[\"let\",\"component\",\"yield\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-menu.hbs",
    "isStrictMode": false
  });
  class DataTableMenuComponent extends _component2.default {}
  _exports.default = DataTableMenuComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableMenuComponent);
});