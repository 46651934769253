define("ember-data-table/components/data-table/data-cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      columns=@columns
      firstColumn=@firstColumn
      otherColumns=@otherColumns
      item=@item
      label=@column
      customFields=@customFields
      linkedRoute=@linkedRoute
      isCustom=this.isCustom
      hasCustom=this.hasCustom
      value=(get @item @column))}}
  */
  {
    "id": "igZ13FWN",
    "block": "[[[18,8,[[28,[37,1],null,[[\"columns\",\"firstColumn\",\"otherColumns\",\"item\",\"label\",\"customFields\",\"linkedRoute\",\"isCustom\",\"hasCustom\",\"value\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,0,[\"isCustom\"]],[30,0,[\"hasCustom\"]],[28,[37,2],[[30,4],[30,5]],null]]]]]]],[\"@columns\",\"@firstColumn\",\"@otherColumns\",\"@item\",\"@column\",\"@customFields\",\"@linkedRoute\",\"&default\"],false,[\"yield\",\"hash\",\"get\"]]",
    "moduleName": "ember-data-table/components/data-table/data-cell.hbs",
    "isStrictMode": false
  });
  class DataTableDataCellComponent extends _component2.default {
    get isCustom() {
      return this.args.customFields?.split(" ").includes(this.args.column);
    }
    get hasCustom() {
      return this.args.customFields;
    }
  }
  _exports.default = DataTableDataCellComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableDataCellComponent);
});