define("ember-data-table/components/data-table/data-table-content-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      enableSelection=@enableSelection
      enableLineNumbers=@enableLineNumbers
      parsedFields=@data-table.parsedFields
      currentSorting=@data-table.sort
      updateSort=@updateSort
      customHeaders=@customHeaders
      hasLinks=@hasLinks
      dataHeadersInfo=(hash
        parsedFields=@data-table.parsedFields
        currentSorting=@data-table.sort
        customHeaders=@customHeaders
        updateSort=@updateSort)
      ThSortable=(component
        "data-table/th-sortable"
        currentSorting=@data-table.sort
        customHeaders=@customHeaders
        updateSort=@updateSort))}}
  */
  {
    "id": "PzHJ1Q/u",
    "block": "[[[18,7,[[28,[37,1],null,[[\"enableSelection\",\"enableLineNumbers\",\"parsedFields\",\"currentSorting\",\"updateSort\",\"customHeaders\",\"hasLinks\",\"dataHeadersInfo\",\"ThSortable\"],[[30,1],[30,2],[30,3,[\"parsedFields\"]],[30,3,[\"sort\"]],[30,4],[30,5],[30,6],[28,[37,1],null,[[\"parsedFields\",\"currentSorting\",\"customHeaders\",\"updateSort\"],[[30,3,[\"parsedFields\"]],[30,3,[\"sort\"]],[30,5],[30,4]]]],[50,\"data-table/th-sortable\",0,null,[[\"currentSorting\",\"customHeaders\",\"updateSort\"],[[30,3,[\"sort\"]],[30,5],[30,4]]]]]]]]]],[\"@enableSelection\",\"@enableLineNumbers\",\"@data-table\",\"@updateSort\",\"@customHeaders\",\"@hasLinks\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-content-header.hbs",
    "isStrictMode": false
  });
  class DataTableContentHeaderComponent extends _component2.default {}
  _exports.default = DataTableContentHeaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableContentHeaderComponent);
});