define("ember-mu-transform-helpers/transforms/date", ["exports", "@ember-data/serializer/-private"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DateTransform extends _private.DateTransform {
    serialize(date) {
      if (date instanceof Date) {
        return formatISODate(date);
      } else {
        return null;
      }
    }
  }
  _exports.default = DateTransform;
  function formatISODate(date) {
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
  }
});