define("ember-data-table/components/data-table/data-table-content-body", ["exports", "@ember/component", "@ember/template-factory", "@ember/service", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _service, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
      isLoading=@data-table.isLoading
      content=@content
      offset=this.offset
      wrappedItems=this.wrappedItems
      enableLineNumbers=@enableLineNumbers
      hasClickRowAction=(and (@onClickRow @rowLink) true)
      onClickRow=@onClickRow
      toggleSelected=this.updateSelection
      selection=@data-table.selection
      enableSelection=@enableSelection
      linkedRoutes=@linkedRoutes
      rowLink=@rowLink
      noDataMessage=@noDataMessage
      customFields=@customFields
      Row=(component "data-table/row"
        data-table=@data-table
        enableLineNumbers=@enableLineNumbers
        enableSelection=@enableSelection
        selection=@data-table.selection
        offset=this.offset
        hasClickRowAction=(and (or @onClickRow @rowLink) true)
        onClickRow=this.onClickRow
        linkedRoutes=@linkedRoutes
        customFields=@customFields
        toggleSelected=this.updateSelection))}}
  
  */
  {
    "id": "oZ6GKhr+",
    "block": "[[[18,10,[[28,[37,1],null,[[\"isLoading\",\"content\",\"offset\",\"wrappedItems\",\"enableLineNumbers\",\"hasClickRowAction\",\"onClickRow\",\"toggleSelected\",\"selection\",\"enableSelection\",\"linkedRoutes\",\"rowLink\",\"noDataMessage\",\"customFields\",\"Row\"],[[30,1,[\"isLoading\"]],[30,2],[30,0,[\"offset\"]],[30,0,[\"wrappedItems\"]],[30,3],[28,[37,2],[[28,[30,4],[[30,5]],null],true],null],[30,4],[30,0,[\"updateSelection\"]],[30,1,[\"selection\"]],[30,6],[30,7],[30,5],[30,8],[30,9],[50,\"data-table/row\",0,null,[[\"data-table\",\"enableLineNumbers\",\"enableSelection\",\"selection\",\"offset\",\"hasClickRowAction\",\"onClickRow\",\"linkedRoutes\",\"customFields\",\"toggleSelected\"],[[30,1],[30,3],[30,6],[30,1,[\"selection\"]],[30,0,[\"offset\"]],[28,[37,2],[[28,[37,4],[[30,4],[30,5]],null],true],null],[30,0,[\"onClickRow\"]],[30,7],[30,9],[30,0,[\"updateSelection\"]]]]]]]]]],[1,\"\\n\"]],[\"@data-table\",\"@content\",\"@enableLineNumbers\",\"@onClickRow\",\"@rowLink\",\"@enableSelection\",\"@linkedRoutes\",\"@noDataMessage\",\"@customFields\",\"&default\"],false,[\"yield\",\"hash\",\"and\",\"component\",\"or\"]]",
    "moduleName": "ember-data-table/components/data-table/data-table-content-body.hbs",
    "isStrictMode": false
  });
  let DataTableContentBodyComponent = (_class = class DataTableContentBodyComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get offset() {
      var offset = 1; //to avoid having 0. row
      var page = this.args['data-table'].page; // TODO: pass on page directly?
      var size = this.args['data-table'].size; // TODO: pass on size directly?
      if (page && size) {
        offset += page * size;
      }
      return offset;
    }
    get wrappedItems() {
      const selection = this.args['data-table'].selection || []; // TODO: should the data-table ensure this is an array?
      const content = this.args.content;
      return content.map(function (item) {
        return {
          item: item,
          isSelected: selection.includes(item)
        };
      });
    }
    updateSelection(selectedWrapper, event) {
      selectedWrapper.isSelected = event.target.checked;
      this.wrappedItems.forEach(wrapper => {
        if (wrapper.isSelected) {
          this.args['data-table'].addItemToSelection(wrapper.item); // TODO: pass on addItemToSelection directly?
        } else {
          this.arg['data-table'].removeItemFromSelection(wrapper.item); // TODO: pass on removeItemFromSelection directly?
        }
      });
    }

    onClickRow() {
      if (this.args.onClickRow) this.args.onClickRow(...arguments);else if (this.args.rowLink) this.router.transitionTo(this.args.rowLink, arguments[0]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickRow"), _class.prototype)), _class);
  _exports.default = DataTableContentBodyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataTableContentBodyComponent);
});